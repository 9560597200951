import { Suspense, useEffect } from "react";
import { MainRouter } from "router";
import ReactGA from "react-ga";
import { BrowserRouter, useLocation } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { mobileActions } from "./redux/slices/mobile/mobileSlice";
import { userActions } from "./redux/slices/user/userSlice";
import Preloader from "./components/Preloader/preloader";

const googleAnalyticsTrackId: any =
  process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;

ReactGA.initialize(googleAnalyticsTrackId);

function ScrollToTop() {
  const { pathname, search } = useLocation();

  useEffect(() => {
    ReactGA.pageview(pathname + search);
  }, [pathname, search]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const App = () => {
  const userData = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  function screenSizeUpdate() {
    if (window.innerWidth <= 800) {
      dispatch(mobileActions.setMobile());
    } else if (window.innerWidth > 800) {
      dispatch(mobileActions.resetMobile());
    }
  }
  window.onresize = screenSizeUpdate;
  useEffect(() => {
    //listen for mobile screen size
    screenSizeUpdate();

    dispatch(userActions.fetchUser());
  }, []);

  useEffect(() => {
    let location = window.location;
    // console.log(userData);
    if (userData.isAuth && localStorage.getItem("rememberMe") === "true") {
      if (userData.details.agent && !location.pathname.startsWith("/agent")) {
        // console.log("Enter agent dashboard");
        location.replace("/agent/overview");
      } else if (
        (userData.details.student &&
          !location.pathname.startsWith("/prospects")) ||
        location.pathname === "/prospects/login"
      ) {
        // console.log("Enter student dashboard");
        location.replace("/prospects/overview");
      }
    }
  }, [userData.isAuth]);

  if (userData.loading) {
    return <Preloader />;
  }
  return (
    <div>
      <BrowserRouter>
        <Suspense fallback={<Preloader />}>
          <ScrollToTop />
          <MainRouter />
        </Suspense>
      </BrowserRouter>
    </div>
  );
};

export default App;
